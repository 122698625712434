window.GetHooked = {}

const afterLoad = () => {}

document.addEventListener('DOMContentLoaded', afterLoad)

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
      console.log("HMR")
    })
}
